/* 
--
user and notification section
--
*/
.nav-left {
  width: 230px !important; /* Adjust based on your design */
}

.nav-middle {
  flex-grow: 1 !important; /* Allows this section to expand */
}

.nav-end {
  width: 230px !important; /* Adjust based on your design */
}
.bell {
  position: relative;
  color: var(--orange);
  margin-right: 2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.bell:hover {
  transform: scale(1.2);
}

.bell-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  font-size: 0.75rem;
}

.user {
  transition: color 0.3s ease;
}

.user:hover {
  color: var(--orange);
}

.name {
  font-size: 1rem;
}

.role {
  font-size: 0.875rem;
  color: #6c757d;
}

/* DropdownMenu.css */

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s ease;
  width: auto;
}

.dropdown-item:hover {
  background: linear-gradient(45deg, rgb(255, 127, 0), rgb(255, 158, 61));
  color: white;
}

.dropdown-item .dropdown-icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.dropdown-item span {
  white-space: nowrap;
}

.dropdown-menu {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(0, 0, 0, 0.15); /* Soft shadow with two layers */
  border-radius: 5px;
}
