.task-form-container {
  /* background: var(--sidebar-bg); */
  /* padding: 20px; */
  /* border-radius: 10px; */
  /* margin-top: 20px; */
  /* transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; */
  /* font-family: "Inter", sans-serif; */
}

/* .task-form-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.form-group {
  margin-bottom: 15px;
}

/* .form-control {
  background-color: var(--textbox-input) !important;
  border-radius: 34px !important;
  border: 1px solid var(--textbox-outline) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

.remarks_form_control {
  border-radius: 12px !important;
}

.form-actions {
  /* margin-top: 20px !important;
  padding-top: 20px !important; */
  display: flex;
  justify-content: space-evenly;
}

/* Button styles */
.create-btn {
  padding: 10px 30px !important;
  transition: background-color 0.3s ease;
  color: var(--white) !important;
  background: linear-gradient(45deg, #32cd32, #228b22) !important;
  transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out !important;
  box-shadow: inset 0 4px 6px rgba(173, 255, 47, 0.5),
    0 6px 12px rgba(0, 0, 0, 0.2) !important;
}
.cancel-btn {
  padding: 10px 30px !important;
  transition: background-color 0.3s ease;
  color: var(--white) !important;
  background: linear-gradient(45deg, #ffcc00, #ff9933);
  transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out !important;
  box-shadow: inset 0 4px 6px rgba(255, 224, 47, 0.5),
    0 6px 12px rgba(0, 0, 0, 0.2) !important;
}

.create-btn:hover,
.cancel-btn:hover {
  box-shadow: inset 0 4px 6px rgba(173, 255, 47, 0.6),
    0 8px 16px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}
.subtaskContainer {
  height: 80vh !important;
  overflow-y: auto !important;
}